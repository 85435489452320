<template>
  <div>
    <div class="diyimokuai">
      <img src="../assets/guanyuwomen.png" class="biaoti" />
      <img src="../assets/1.png" class="bg1" />
      <div class="biaoti1">西安盛行网络科技有限公司</div>
      <div class="biaoti2">
        服务合作伙伴场景覆盖
        <div style="display: inline-block; color: red">1万+</div>
      </div>
      <div class="extenddd">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;西安盛行网络科技有限公司是“长安大学科技园大宗商品互联网产业应用实验室”的主办单位，承担国家级大宗商品行业的数据调研工作，先后为国家商务部提供西北地区大宗商品行业报告、为陕西省政府提供陕西省能源行业互联网应用调研报告，为中国人民银行第三方支付企业设计金融结算、清算、支付系统，公司以权益平台和技术赋能为依托，为通信、出行、金融等行业提供优质的OT解决方案。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司拥有自主知识产权的“聚合支付平台”、“大宗商品交易系统”、“大数据应用分析系统”、“企业信息管理系统”、“乐访管理系统”等产品系统，公司通过了国家级“高新技术企业认证”、“软件企业认定”、“软件产品认定”、“ISO9001质量管理体系认证”、CMMI3级认证等，公司也是西安交通大学数据实验室的合作单位，为陕西商用数据分析提供技术服务支持。 2019年7月，荣获陕西省慈善协会颁发的“慈善荣誉证书”
        。
      </div>
    </div>
    <div class="diermokuai">
      <img src="../assets/wenhua.png" class="biaoti" style="width: 461px" />
      <img src="../assets/2.png" class="bg2" />
      <div class="diermokuaiList">
        <div
          class="diermokuaiItem"
          v-for="(item, index) in diermokuaiList"
          :key="index"
        >
          <img :src="item.pic" />
          <div class="diermokuaiItem_title">
            <div class="diermokuaiItem_title1">{{ item.title }}</div>
            <div class="diermokuaiItem_title2">{{ item.extend }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="disanmokuai">
      <img src="../assets/rongyu.png" class="biaoti" style="width: 409px" />
      <div class="sangexuanxiang">
        <div
          class="sangexuanxiang_item"
          :class="{ sangexuanxiang_item_active: index == selectIndex }"
          v-for="(item, index) in sangexuanxiangList"
          :key="index"
          @click="changesangexuanxiangItem(index)"
        >
          {{ item }}
        </div>
      </div>
      <div v-if="selectIndex == 0">
        <img
          src="../assets/著作权证.png"
          style="position: absolute; top: 327px; left: 358px"
        />
      </div>
      <div v-if="selectIndex == 1">
        <img
          src="../assets/行业证书.png"
          style="position: absolute; top: 327px; left: 50%; margin-left: -446px"
        />
      </div>
      <div v-if="selectIndex == 2">
        <img
          src="../assets/资质证书.png"
          style="position: absolute; top: 327px; left: 50%; margin-left: -600px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      selectIndex: "",
      sangexuanxiangList: ["资质证书", "行业证书", "著作权证"],
      diermokuaiList: [
        {
          pic: require("../assets/11.png"),
          title: "以设计为先驱",
          extend: "专业的艺术指导，资深的设计团队，灵感闪动，善于创新。",
        },
        {
          pic: require("../assets/12.png"),
          title: "以程序为内核",
          extend: "资深的开发团队，扎实的技术伦理和丰富的实践经验。",
        },
        {
          pic: require("../assets/13.png"),
          title: "以策划为理念",
          extend: "优秀的前期策划为您定制专业的趋势方案。",
        },
        {
          pic: require("../assets/14.png"),
          title: "以创新为动力",
          extend: "依靠科学强大力量，不懈的钻研与拼搏，讲究求实创新。",
        },
      ],
    };
  },
  methods: {
    changesangexuanxiangItem(index) {
      this.selectIndex = index;
    },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
      })();
    };
  },
   watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth);
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.diyimokuai {
  width: 100%;
  height: 764px;
  background: url("../assets/beijing5.png") no-repeat 100% 100%;
  position: relative;
}
.biaoti {
  width: 431px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 745px;
}
.bg1 {
  position: absolute;
  top: 252px;
  left: 360px;
}
.biaoti1 {
  height: 28px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #8d0000;
  line-height: 28px;
  position: absolute;
  top: 280px;
  left: 410px;
}
.biaoti2 {
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  position: absolute;
  top: 326px;
  left: 479px;
}
.extenddd {
  position: absolute;
  top: 290px;
  left: 815px;
  width: 744px;
  height: 307px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
}
.diermokuai {
  position: relative;
  width: 100%;
  height: 835px;
}
.bg2 {
  width: 520px;
  height: 364px;
  position: absolute;
  top: 317px;
  left: 359px;
}
.diermokuaiList {
  width: 600px;
  height: 443px;
  position: absolute;
  top: 292px;
  left: 935px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.diermokuaiItem {
  height: 75px;
  display: flex;
}
.diermokuaiItem_title {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.diermokuaiItem_title1 {
  height: 23px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.diermokuaiItem_title2 {
  height: 19px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}
.disanmokuai {
  height: 910px;
  width: 100%;
  background-color: #f7f7f7;
  position: relative;
}
.sangexuanxiang {
  position: absolute;
  left: 685px;
  top: 241px;
  width: 550px;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.sangexuanxiang_item {
  width: 136px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}
.sangexuanxiang_item_active,
.sangexuanxiang_item:hover {
  color: #fff;
  background: #a8111a;
  border-radius: 8px;
}
</style>